<template>
  <div class="login text-center ma mt-8">
    <form @submit.prevent="submit" :id="form_id">
      <div class="rounder">
        <div class="card_header ubuntu_b">
            <v-btn color="#eee" fab x-small depressed class="white_circle back mob" @click="back"><v-img :src="require(`@/assets/img/left_red.png`)" contain></v-img></v-btn>
            Promjeni lozinku
        </div>
        <div class="input_holder border_top">
          <v-text-field
            v-model="old_password"
            :error-messages="old_passwordErrors"
            :label="t_old_password"
            solo
            v-eager
            :type="(old_password.length)?`password`:`text`"
            @input="$v.old_password.$touch()"
            @blur="$v.old_password.$touch()"
          ></v-text-field>
        </div>
        <div class="input_holder border_top">
          <v-text-field
            v-model="new_password"
            :error-messages="new_passwordErrors"
            :label="t_new_password"
            solo
            v-eager
            :type="(new_password.length)?`password`:`text`"
            @input="$v.new_password.$touch()"
            @blur="$v.new_password.$touch()"
          ></v-text-field>
        </div>
        <div class="input_holder border_top">
          <v-text-field
            v-model="password_rep"
            :error-messages="password_repErrors"
            :label="t_password_rep"
            solo
            v-eager
            :type="(password_rep.length)?`password`:`text`"
            @input="$v.password_rep.$touch()"
            @blur="$v.password_rep.$touch()"
          ></v-text-field>
        </div>
        <v-btn class="rounder_bottom submit_btn ubuntu_b" color="secondary" type="submit" :disabled="submitStatus === 'PENDING'">
          Izmjeni
          <div class="white_circle"><v-img :src="require(`@/assets/img/right_red.png`)" contain></v-img></div>
        </v-btn>
      </div>
      
    </form>
    <div class="text-center pt-2">
        <p class="typo__p" v-if="submitStatus === 'OK'">Email sa linkom za potvrdu je poslan na Vašu Email adresu</p>
        <p class="typo__p error-txt" v-if="submitStatus === 'ERROR'">Molimo Vas ispunite formu pravilno</p>
        <p class="typo__p" v-if="submitStatus === 'PENDING'">Šaljem...</p>
        <p class="typo__p error-txt" v-if="submitError !== ''">{{ submitError }}</p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      form_id: 'changePassword',
      new_password: '',
      old_password: '',
      password_rep:'',
      form_enabled: false,
      t_old_password: 'Stara lozinka',
      t_new_password: 'Nova lozinka',
      t_password_rep: 'Ponovi lozinku'
    }
  },
  mixins: [validationMixin],
  
  validations: {
    new_password: {
        required,
        minLength:minLength(6)
    },
    old_password: {
        required,
        minLength:minLength(6)
    },
    password_rep: {
        required,
        sameAsNewPassword: sameAs('new_password')
    },
  },
  computed: {
    ...mapGetters({
      submitStatus: 'forms/submitStatus',
      submitError: 'forms/submitError',
      user_id: 'user/userId'
    }),
    new_passwordErrors () {
      const errors = []
      if (!this.$v.new_password.$dirty) return errors
      !this.$v.new_password.minLength && errors.push('Nova lozinka mora sadžati barem 6 znakova')
      !this.$v.new_password.required && errors.push('Nova lozinka je obavezna')
      return errors
    },
    old_passwordErrors () {
      const errors = []
      if (!this.$v.old_password.$dirty) return errors
      !this.$v.old_password.minLength && errors.push('Stara lozinka mora sadžati barem 6 znakova')
      !this.$v.old_password.required && errors.push('Stara lozinka je obavezna')
      return errors
    },
    password_repErrors () {
      const errors = []
      if (!this.$v.password_rep.$dirty) return errors
      !this.$v.password_rep.required && errors.push('Ponavljanje nove lozinke je obavezno')
      !this.$v.password_rep.sameAsNewPassword && errors.push("Ponovljena lozinka nije identična")
      return errors
    }
  },
  methods: {
    submit () {
      var formPass = false
      this.$v.$touch()
      formPass = this.$v.$invalid
      if (formPass) {
        this.$store.dispatch('forms/changeSubmitStatus','ERROR')
      } else {
        this.$store.dispatch('forms/changeSubmitStatus','PENDING')
        let formData = {
          new_password: this.new_password,
          old_password: this.old_password,
          user_id: this.user_id
        }
        this.$store.dispatch('forms/submitForm', { form: this.form_id, data: formData })
      }
    },
    back(){
        this.$router.go(-1)
    }
  },
  destroyed(){
      this.$store.dispatch('forms/changeSubmitStatus',null)
      this.$store.dispatch('forms/changeSubmitError',null)
  }

};
</script>
<style scoped>
    form{
      width:100% !important;
    }
    .card_header, .card_content{
        position:relative;
    }
    .card_content{
        padding:10px 20px 20px 50px;
    }
    .card_header{
        background: #FFF;
        padding-left:50px;
        line-height: 47px;
        height:47px;
    }
    .back{
        position:absolute;
        width:26px;
        height:26px;
        left:13px;
        top:13px;
    }
</style>
