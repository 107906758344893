<template>
  <v-container>
    <v-row dense>
        <v-col lg="6" offset-lg="3" md="10" offset-md="1" cols="12">
            <changePasswordComponent />
        </v-col>
    </v-row>
  </v-container> 
</template>
<script>
import changePasswordComponent from '../components/ChangePasswordComponent.vue'
export default {
    data() {
        return {
        }
    },
    components: { changePasswordComponent },
}
</script>